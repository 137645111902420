import { SigpacServiceService } from './sigpac-service.service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/compat/app';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import * as firebaseui from 'firebaseui';
import { Observable, combineLatest } from 'rxjs';
import { environment } from 'environments/environment';
import { FirebaseApp } from '@angular/fire/compat';
import * as PolyUtil from 'google-maps-polyutil';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FirebasePaged } from './firebase-component';
import { collection, where } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { Firestore,addDoc,doc ,setDoc, getDoc, deleteDoc,updateDoc, docData, query} from '@angular/fire/firestore';
import { collectionData } from 'rxfire/firestore';
import { map } from 'rxjs/operators';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisorFirebaseService {


  premium:Boolean;

  sendEmailVerification() {
    var actionCodeSettings = {
        url:'https://visorsigpac2.aidiapp.com/pages/emailverification?' + this.angularFire.auth().currentUser.email,
        handleCodeInApp: true,
    };
    this.angularFire.auth().currentUser.sendEmailVerification(actionCodeSettings)
  }

  ui:any;

  constructor(protected angularFire: FirebaseApp,private afs: AngularFirestore,
    private analytics: AngularFireAnalytics,

    private sigpacservice: SigpacServiceService,
    private firestore: Firestore) {


   // firebase.default.initializeApp(environment.firebase);

    console.log("Inicializamos FIREBASE")
    this.ui = new firebaseui.auth.AuthUI(angularFire.auth());
  }

  getUser():Observable<any>{
    return new Observable((observer) => {

      this.angularFire.auth().onAuthStateChanged((user) => {
        observer.next(user);

        if(user == null) {
         // console.log("Desactivamos premium")
          this.premium = null;
        }
      });


      observer.next(firebase.default.auth().currentUser);

    });
  }

  checkPremium():Observable<Boolean>{
    return new Observable((observer) => {
        if(this.premium != null){
          observer.next(this.premium);
        }else{
          this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).ref.get().then((doc) => {
            this.premium = (doc.data() as any).premium;
            observer.next(this.premium);
        });
        }
    });
    }

  initAuthUi(container:string):Observable<any>{

    return new Observable((observer) => {

      const appleProvider = new firebase.default.auth.OAuthProvider('apple.com');
      appleProvider.addScope('email');
      appleProvider.addScope('name');
      this.ui.start(container, {
        signInOptions: [
          firebase.default.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.default.auth.GoogleAuthProvider.PROVIDER_ID,
          'apple.com',
        ],
        callbacks: {
          signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            console.log(authResult);
            observer.next(firebase.default.auth().currentUser);
            return false;
          }
        },
        // Other config options...
      });
    })

  }


  obtenerMaquinaria() : Observable<any> {
    let userid = this.angularFire.auth().currentUser.uid;
     return collectionData(collection(this.firestore,`users/${userid}/machinery`), {idField: 'identifier'});
  }
  guardarMaquinaria(maq: any): Promise<any>{
    let userid = this.angularFire.auth().currentUser.uid;
    let uid = maq.id;
    if(uid != undefined && null != uid){
      delete maq.id;
      let docReference = doc(this.firestore,`users/${userid}/machinery/${uid}`);
     return setDoc(docReference,maq);
    } else {
      return addDoc(collection(this.firestore,`users/${userid}/machinery`), maq);
    }

  }

  eliminarMaquinaria(maqid: string): Promise<any>{
    let userid = this.angularFire.auth().currentUser.uid;
    let docReference = doc(this.firestore,`users/${userid}/machinery/${maqid}`);
    return deleteDoc(docReference);
  }


  obtenerAsesoresAplicadores() : Observable<any> {
    let userid = this.angularFire.auth().currentUser.uid;
     return collectionData(collection(this.firestore,`users/${userid}/advisorsappliers`), {idField: 'identifier'});
  }
  guardarAsesoresAplicadores(maq: any): Promise<any>{
    let userid = this.angularFire.auth().currentUser.uid;
    let uid = maq.id;
    if(uid != undefined && null != uid){
      delete maq.id;
      let docReference = doc(this.firestore,`users/${userid}/advisorsappliers/${uid}`);
     return setDoc(docReference,maq);
    } else {
      return addDoc(collection(this.firestore,`users/${userid}/advisorsappliers`), maq);
    }

  }

  eliminarAsesoresAplicadores(maqid: string): Promise<any>{
    let userid = this.angularFire.auth().currentUser.uid;
    let docReference = doc(this.firestore,`users/${userid}/advisorsappliers/${maqid}`);
    return deleteDoc(docReference);
  }

  obtenerParcela(fieldReference: any): Observable<any> {
    let userid = this.angularFire.auth().currentUser.uid;
    let docReference =  doc(this.firestore,`users/${userid}/fields/${fieldReference}`);
    return docData(docReference);
  }

  obtenerRegistros(selectedParcelas: any[],selectedRegistros: any[]): FirebasePaged {

    var filtros = [];
    if(selectedParcelas != undefined && selectedParcelas != null && selectedParcelas.length > 0){
      filtros.push(where('id','in',selectedParcelas));
    }

    if(selectedRegistros != undefined && selectedRegistros != null && selectedRegistros.length > 0){
      filtros.push(where('recordType','in',selectedRegistros));
    }
    let userid = this.angularFire.auth().currentUser.uid;
    return new FirebasePaged(collection(this.firestore,`users/${userid}/records`), filtros, 3);
  }


  eliminarRegistro(idRegistro: string): Promise<any>{
    let userid = this.angularFire.auth().currentUser.uid;
    let docReference = doc(this.firestore,`users/${userid}/records/${idRegistro}`);
    return deleteDoc(docReference);

  }
  guardarRegistro(registro: any):Promise<any>{
    let userid = this.angularFire.auth().currentUser.uid;
    let registroId = registro.id;
    delete registro.id;
    let docReference = doc(this.firestore,`users/${userid}/records/${registroId}`);
   return updateDoc(docReference,registro);
  }

  obtenerParcelas() : Observable<any>{
    this.analytics.logEvent('obtener_parcelas')
    return new Observable((observer) => {
      let userid = this.angularFire.auth().currentUser.uid;
     // userid = 'vUxmAVDK7ZM6x2HjV35PHtpNrvG2';


     collectionData(collection(this.firestore,`users/${userid}/fields`), {idField: 'id'}).subscribe(async (doc) => {

        for(let parcela of doc){
          if(parcela.path != null){

            //console.log("Generamos geometria")
            parcela.geometry = this.generateGeometry(parcela.path);
            //console.log(parcela.geometry);

          }else{
            //console.log("Obtenemos geometria SIGPAC")
            const parcelasigpac = await this.sigpacservice.getParcela(parcela.params);
            parcela.geometry = parcelasigpac.geometry;
            //console.log(parcelasigpac);
          }

          this.generateParcelaParams(parcela);
        }

        console.log('hemos terminado de tratar los datos');
        observer.next(doc);
     });

    });

  }

  eliminarRegistroDiario(idParcela:string,idRegistro: string) :Observable<any>{
    this.analytics.logEvent('emliminar_registro_diario')
    return new Observable((observer) => {
      this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(idParcela)
      .collection('records').doc(idRegistro).delete().then((confirmed) =>{
          observer.next(confirmed);
      });
    });
  }

  actualizaRegistroDiario(idParcela:string, diario: any) :Observable<any>{
    this.analytics.logEvent('actualizar_registro_diario')
    return new Observable((observer)=>{
        let idRegistro = diario.id;
        delete diario.id;
        diario.cantidad = +Number(diario.cantidad)
        diario.afectado = +Number(diario.afectado)
        this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(idParcela)
      .collection('records').doc(idRegistro).update(diario).then((updated)=>{
        observer.next(updated);
      });
    });
  }

  creaRegistroDiario(idParcela:string,diario: any) :Observable<any>{
    this.analytics.logEvent('crear_registro_diario')
    return new Observable((observer)=>{
      diario.cantidad = Number(diario.cantidad)
      diario.afectado = Number(diario.afectado)
        this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(idParcela)
      .collection('records').add(diario).then((diarioCreated) => {
        diario.id = diarioCreated.id;
        observer.next(diario);
      });
    });
  }


  obtenerSeasons():Observable<any> {
    return new Observable((observer) => {
      this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('seasons').get().subscribe((snapshot)=>{
        let seasons = [];
        snapshot.forEach((doc)=>{
          let registro = JSON.parse(JSON.stringify(doc.data()));
          registro.id = doc.id;


          seasons.push(registro);
        })
        observer.next(seasons);
      });
    });
  }

  obtenerCurrentSeason(): Observable<any> {
    return new Observable((observer) => {
      this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('seasons').get().subscribe((snapshot) => {
        let seasons = [];
        snapshot.forEach((doc) => {
          let registro = JSON.parse(JSON.stringify(doc.data()));
          registro.id = doc.id;
          seasons.push(registro);
        });

        const currentSeason = seasons.find(season => !season.fechaFin || season.fechaFin === null);

        if (currentSeason) {
          observer.next(currentSeason.name);
        } else {
          observer.next(null);
        }
      });
    });
  }

  newSeason(nameNewSeason, fechaInicioNewSeason) {
    // Recuperar la temporada actual
    this.obtenerCurrentSeason().subscribe((currentSeasonName: string | null) => {
      if (currentSeasonName) {
        // Si existe una temporada actual, actualizar su fechaFin con el día de hoy
        this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('seasons').ref
          .where('name', '==', currentSeasonName).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const today = new Date();
              const fechaFin = {
                seconds: Math.floor(today.getTime() / 1000), // Convertir la fecha actual a segundos
                nanoseconds: (today.getTime() % 1000) * 1000000 // Convertir milisegundos a nanosegundos
              };
              this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('seasons').doc(doc.id).update({
                fechaFin: fechaFin
              });
            });
          });
      }

      var fIni = new Date();
      if (fechaInicioNewSeason != '' && fechaInicioNewSeason != null) {
        fIni = fechaInicioNewSeason;
      }
      const nuevaTemporada = {
        name: nameNewSeason,
        fechaInicio: {
          seconds: Math.floor(fIni.getTime() / 1000),
          nanoseconds: (fIni.getTime() % 1000) * 1000000
        },
        fechaFin: null
      };

      // Añadir la nueva temporada a la base de datos
      this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('seasons').add(nuevaTemporada)
        .then(() => {
          console.log('Nueva temporada creada con éxito');
        })
        .catch((error) => {
          console.error('Error al crear la nueva temporada:', error);
        });

    });
  }


  updateParcela(p: any):Observable<any>{
    return new Observable((observer) => {
      var pid = p.id;
      delete p.id;
      var docRef = this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(pid);

      docRef.update(p).then((exito) => {
          observer.next(exito);
      });
    });
  }

  obtenerDiario(idParcela: string)  :Observable<any>{
    this.analytics.logEvent('obtener_diario')
    return new Observable((observer) => {
      this.afs.collection('users').doc(this.angularFire.auth().currentUser.uid).collection('fields').doc(idParcela)
      .collection('records').get().subscribe((snapshot)=>{
        let diario = [];
        snapshot.forEach((doc)=>{
          let registro = JSON.parse(JSON.stringify(doc.data()));
          registro.id = doc.id;


          diario.push(registro);
        })
        observer.next(diario)
      });
    })

  }
  generateParcelaParams(parcela: any) {
    let parcParams = parcela.params.split(',');
    parcela.provincia = parcParams[0];
    parcela.municipio = parcParams[1];
    parcela.agregado = parcParams[2];
    parcela.zona = parcParams[3];
    parcela.poligono = parcParams[4];
    parcela.parcela = parcParams[5];
    parcela.recinto = parcParams[6];
  }

  generateGeometry(path: string):any{
    let geometry = {
      type:'Polygon',
      coordinates:[[]],

    }

    if(path.split("|").length > 2){
      //Es iOS

      try{
        path.split("|").forEach((coord) => {
          let cp = coord.split(':');
          if (cp.length != 2){
            throw new Error(('No se puede generar coordenadas'));
          }
          geometry.coordinates[0].push([Number(cp[1]),Number(cp[0])]);
        })
      } catch(e) {
        let coords = PolyUtil.decode(path).reverse();
        coords.forEach((coordp) => {

          geometry.coordinates[0].push([coordp.longitude,coordp.latitude])
        });
      }
    } else {
      let coords = PolyUtil.decode(path).reverse();
      coords.forEach((coordp) => {

        geometry.coordinates[0].push([coordp.longitude,coordp.latitude])
      });
    }




    return geometry;
  }
  closeSession() {
    this.angularFire.auth().signOut();
  }

  obtenerGroupAdministrator(): Observable<any> {
    const userId = this.angularFire.auth().currentUser.uid;
    const userDoc = doc(this.firestore, `users/${userId}`);
    return docData(userDoc, { idField: 'id' }).pipe(
      map((data: any) => data.groupAdministrator)
    );
  }
  
  obtenerUsersAdministrated(): Observable<any> {
    const currentUserId = this.angularFire.auth().currentUser?.uid;
  
    const usersRef = collection(this.firestore, 'users');
  
    // Consulta para los usuarios "Aceptado"
    const acceptedQuery = query(
      usersRef,
      where('groupUserAdministrator', '==', currentUserId),
      where('groupRequestAdministrator', '==', currentUserId)
    );
  
    // Consulta para los usuarios "Pendiente"
    const pendingQuery = query(
      usersRef,
      where('groupUserAdministrator', '==', ''),
      where('groupRequestAdministrator', '==', currentUserId)
    );
  
    // Ejecutar ambas consultas
    const acceptedUsers$ = collectionData(acceptedQuery, { idField: 'id' }).pipe(
      map((users: any[]) =>
        users.map((user) => ({ email: user.email, estado: 'Aceptado' }))
      )
    );
    
    const pendingUsers$ = collectionData(pendingQuery, { idField: 'id' }).pipe(
      map((users: any[]) =>
        users.map((user) => ({ email: user.email, estado: 'Pendiente' }))
      )
    );
  
    return combineLatest([acceptedUsers$, pendingUsers$]).pipe(
      map(([acceptedUsers, pendingUsers]) => [...acceptedUsers, ...pendingUsers])
    );

  }

  fetchUserByEmail(email: string): Observable<{ user: any; situacion: string }> {
    const currentUserId = this.angularFire.auth().currentUser?.uid;
    const usersRef = collection(this.firestore, 'users');
    const emailQuery = query(usersRef, where('email', '==', email));
  
    return collectionData(emailQuery, { idField: 'id' }).pipe(
      take(1), // Asegúrate de tomar solo una emisión
      map((users: any[]) => {
        if (users.length === 0) {
          return { user: null, situacion: 'No encontrado' };
        }
        const user = users[0]; // Asumimos que los emails son únicos
        if (user.groupAdministrator) {
          return { user: null, situacion: 'Administrador' };
        } else if (user.groupRequestAdministrator === currentUserId) {
          return { user: null, situacion: 'Existe' };
        } else {
          return { user, situacion: 'Alta' };
        }
      })
    );
  }  

  // Función para actualizar un usuario
  updateUser(user: any): Promise<any> {
    let currentUserId = this.angularFire.auth().currentUser.uid;
    let idUser = user.id;
    delete user.id;
    user.groupRequestAdministrator = currentUserId;
  
    let docReference = doc(this.firestore, `users/${idUser}`);
  
    return getDoc(docReference).then((docSnapshot) => {
      if (docSnapshot.exists()) {
        return updateDoc(docReference, user);
      } else {
        throw new Error(`El documento con ID ${idUser} no existe.`);
      }
    });
  }

}
