<span class="created-by">
  Desarrollado con ♥ por <b><a href="https://www.aidiapp.com" target="_blank">AIDIAPP</a></b> 2024
</span>

<ngx-simple-ng-adsense [adSlot]="1377180175" [pageLevelAds]="false" [inlineStyle]="'display:inline-block;width:728px;height:90px'"></ngx-simple-ng-adsense>


<!--
<div class="socials">
  <a href="#" target="_blank" class="ion ion-social-github"></a>
  <a href="#" target="_blank" class="ion ion-social-facebook"></a>
  <a href="#" target="_blank" class="ion ion-social-twitter"></a>
  <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
</div>
-->

