import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AdsenseConfig, ADSENSE_TOKEN } from './config';
@Component({
  selector: 'ngx-simple-ng-adsense',
  templateUrl: './simple-ng-adsense.component.html',
  styleUrls: ['./simple-ng-adsense.component.scss']
})
export class SimpleNgAdsenseComponent implements OnInit,AfterViewInit, OnDestroy {

  @Input() adClient!: string;
  /** ad slot/number */
  @Input() adSlot!: string | number;

  @Input() inlineStyle!: string;

  @Input() className = '';

  @Input() pageLevelAds!: boolean;

  @ViewChild('ins', { read: ElementRef, static: true }) ins!: ElementRef;

  constructor(@Inject(ADSENSE_TOKEN) private config: AdsenseConfig,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(PLATFORM_ID) private platform: any) { }
  ngOnDestroy(): void {
    const iframe = this.ins.nativeElement.querySelector('iframe');
    if (iframe && iframe.contentWindow) {
      iframe.src = 'about:blank';
      iframe.remove();
    }
  }

  ngOnInit(): void {

    const config = this.config;
    this.adClient = this.adClient ?? config.adClient;
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.push();
    }
  }

  push(): void {
    const p: Record<string, string | boolean> = {};
    if (this.pageLevelAds) {
      p.google_ad_client = this.adClient;
      p.enable_page_level_ads = true;
    }

    if (window) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(p);
      } catch {
        // pass
      }
    }
  }

}
