
import { Observable, Subscriber } from 'rxjs';


import { Firestore, collection, query, collectionData, where, limit,
   startAfter, CollectionReference, Query, startAt, getDocs, QueryConstraintType, QueryFieldFilterConstraint, DocumentSnapshot, QueryConstraint, QueryCompositeFilterConstraint } from '@angular/fire/firestore';

export class FirebasePaged {

  private  resultSubscriber: Subscriber<any>;


  private lastDoc: DocumentSnapshot;



  private finalQuery: Query;
  constructor(private docCol: CollectionReference,private constraints: QueryConstraint[], private l: number) {



  }

  observe(): Observable<any>{
     let observable = new Observable((observer) => {
        this.resultSubscriber = observer;
        //this.refresh();
     });

     return observable;
  }

  refresh(){



    let finalQ = query(this.docCol, ...this.constraints , limit(this.l));

    if(this.lastDoc != undefined && this.lastDoc != null){
      finalQ = query(this.docCol, ...this.constraints , limit(this.l), startAfter(this.lastDoc));
    }
    getDocs(finalQ).then(qs => {
      this.lastDoc = qs.docs[qs.docs.length - 1];

      this.resultSubscriber.next(qs.docs.map(qd => {
       var data = qd.data();
        data.id = qd.id;
       return data;

    }));
    });



  }


};

