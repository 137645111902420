import { GeoTileBounds } from './geotilebounds';
import { GeoTileSystem } from './geotilesystem';
export class VectorSource {
  
  public static getURLTiles(b: GeoTileBounds,srid: number){
    var r=new Array<string>();
    var gts=new GeoTileSystem(srid);
    var min=gts.XYToTileIJ(b.XMin, b.YMin, 15);
    var max=gts.XYToTileIJ(b.XMax, b.YMax, 15);
    
    for(var h=min.i;h <=max.i;h++){
      for(var p=min.j;p <= max.j;p++){
        var url='@'+srid+'/15.'+h+'.'+p;
        r.push(url);
      }
    }
    return r;
  }
}