import { VisorFirebaseService } from './../../../services/visor-firebase.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('verificationdialog')
  private verificationTpl: TemplateRef<any>;

  mapaIcon = {
    icon:'map',
    package:'fontawesome',
  };
  listadoIcon = {
    icon:'list',
    package:'fontawesome',
  };
  helpIcon = {
    icon:'info',
    package:'fontawesome',
  };
  houseIcon = {
    icon:'home',
    package:'fontawesome',
  };
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  logo: any = window.location.protocol+ "//"+window.location.host + "/assets/images/logo_visor.png";
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Cerrar Sesión',action:'closeSession' } ];
  subscription: any;
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private breakpointService: NbMediaBreakpointsService,
              private visorfirebaseService: VisorFirebaseService,
              private dialogService: NbDialogService,
              private router: Router) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.visorfirebaseService.getUser().subscribe((user) => {
      console.log("USUARIO")
      console.log(user)
      this.user = user;
    })



    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);


      this.subscription = this.menuService.onItemClick().subscribe((event) => {

        const evItem = event.item as any;

        if( evItem.action == 'closeSession'){

          this.visorfirebaseService.closeSession()



        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  gotoLogin(){
    this.router.navigate(['pages','login']);
  }

  verificarEmail(){
    this.dialogService.open(this.verificationTpl);
  }

  enviarEmailVerificacion(){
      this.visorfirebaseService.sendEmailVerification();
  }
}
