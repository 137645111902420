<div class="header-container">
  <div class="logo-container">

    <img id="companylogo" alt="My image name" [src]="logo">
    <!--
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    -->
    <a class="logo" href="#" (click)="navigateHome()">Visor <span>SIGPAC</span></a>
  </div>
  <nb-actions  class="action-btns" size="medium">
      <nb-action [icon]="mapaIcon" link="/pages/visor" title="Mapa">Mapa</nb-action>
      <nb-action *ngIf="user != null && user.emailVerified" [icon]="listadoIcon" link="/pages/listado" title="Registros">Listado</nb-action>
      <nb-action *ngIf="user != null && user.emailVerified" [icon]="houseIcon" link="/pages/explotacion" title="Mi explotacion"></nb-action>
      <nb-action [icon]="helpIcon" link="/pages/ayuda">Ayuda</nb-action>
  </nb-actions>

  <!--
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  <ngx-layout-direction-switcher class="direction-switcher"></ngx-layout-direction-switcher>
  -->
</div>

<div class="header-container">
  <nb-actions size="small">

    <!--<nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
  -->

  <nb-action class="control-item">
    <nb-search type="column-curtain" hint="Buscar localidad" placeholder="Buscar..."></nb-search>
  </nb-action>

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user *ngIf="user != null && user.emailVerified" [nbContextMenu]="userMenu"
               [name]="user?.displayName"
               [picture]="user?.photoURL">
      </nb-user>
      <button nbButton *ngIf="user != null && !user.emailVerified" (click)="verificarEmail()"><nb-icon icon="person-outline" ></nb-icon>Verificar Email</button>
      <button nbButton *ngIf="user == null" (click)="gotoLogin()"><nb-icon icon="person-outline" ></nb-icon>Iniciar Sesión</button>
    </nb-action>
  </nb-actions>
</div>

<ng-template #verificationdialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Verificar Email</nb-card-header>
    <nb-card-body>
      <p>Para poder terminar el proceso de registro de usuario debe verificar su e-mail. Haga clic en Aceptar para que le enviemos su código de verificación y después consulta la bandeja de su correo electrónico</p>

    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col-md-6">
          <button nbButton status="primary" (click)="ref.close()">Cancelar</button>
        </div>
        <div class="col-md-6 text-right">
          <button nbButton status="success" (click)="enviarEmailVerificacion();ref.close()">Enviar</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
