import { InjectionToken } from '@angular/core';

/**
 * Set optional global default values
 */
export interface AdsenseConfig {
  /** adsense account ca-pub-XXXXXXXXXXXXXXXX */
  adClient: string;
}

export const ADSENSE_TOKEN = new InjectionToken<AdsenseConfig>(
  'AdsenseConfig',
);
