import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleNgAdsenseComponent } from './simple-ng-adsense.component';
import { AdsenseConfig, ADSENSE_TOKEN } from './config';


@NgModule({

  imports: [CommonModule],
  exports:[SimpleNgAdsenseComponent],
  declarations: [SimpleNgAdsenseComponent],

})
export class SimpleNgAdsenseModule {
  static forRoot(config: Partial<AdsenseConfig> = {}): ModuleWithProviders<SimpleNgAdsenseModule> {
    return {
      ngModule: SimpleNgAdsenseModule,
      providers: [{ provide: ADSENSE_TOKEN, useValue: config }],
    };
  }
}
