import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';

import { Observable }                                        from 'rxjs/Observable';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import * as olProj from 'ol/proj';
import { GeoTileBounds } from './geotilebounds';
import { VectorSource } from './vectorsource';
@Injectable({
  providedIn: 'root',
})
export class SigpacServiceService {

  protected basePath = 'https://us-central1-visorsigpac-57282.cloudfunctions.net/cors?url=https://sigpac.mapa.es/fega/ServiciosVisorSigpac/LayerInfo';
  protected basePathparcela = 'https://us-central1-visorsigpac-57282.cloudfunctions.net/cors?url=https://sigpac.mapa.es/fega/ServiciosVisorSigpac/';
  protected mapaBasePath = 'https://us-central1-visorsigpac-57282.cloudfunctions.net/cors?url=https://sigpac.mapama.gob.es/vectorsdg/vector/';
  constructor(protected httpClient: HttpClient,private analytics: AngularFireAnalytics) { }

  public getParcelaDetail(geometryParams: any): Observable<any>{
    this.analytics.logEvent('get_parcela_detail',geometryParams);
    var parcid = geometryParams.provincia +','+
    geometryParams.municipio +','+
    geometryParams.agregado +','+
    geometryParams.zona +','+
    geometryParams.poligono +','+
    geometryParams.parcela;

    return this.httpClient.get(`${this.basePath}?${encodeURIComponent(`layer=parcela&id=${parcid}`)}`);

  }

  public getParcela(parcela: string): Promise<any>{
    return new Promise((resolver,error) => {
      console.log('getParcela - INIT');
      console.log(parcela)

        parcela = parcela.replace(',null','');
        console.log(parcela)
        let params = parcela.replace(/,/g, '/');
        let idparts = parcela.split(',')
        let tipo = "parcela"
        let tipoBox = "ParcelaBox/";



      if (idparts.length == 7){
            tipo = 'recinto';
          tipoBox = 'RecintoBox/';
        }

        let urlrequest = this.basePathparcela +'query/' + tipoBox + params + '.json';

      this.httpClient.get(urlrequest).toPromise().then(box => {
          let coords1 = olProj.transform( [box[0].x1, box[0].y1], 'EPSG:4326', 'EPSG:3857');
          let coords2 = olProj.transform( [box[0].x2, box[0].y2], 'EPSG:4326', 'EPSG:3857');
          let gtb = new GeoTileBounds(coords2[0]
            , coords1[0]
            , coords2[1]
            , coords1[1]
            , 3857);
          let urls = VectorSource.getURLTiles(gtb, 3857);
          let nurls = 0;
          const totalUrls = urls.length;

          console.log('método getParcela');
          console.log(urls)

          let urlv = this.mapaBasePath + tipo + urls[0] + '.geojson';

          this.httpClient.get(urlv).toPromise().then(r => {
                nurls++;

                let matchFeature = (r as any).features.find( f => {
                  return f.properties.provincia == +idparts[0] &&
                  f.properties.municipio == +idparts[1] &&
                  f.properties.agregado == +idparts[2] &&
                  f.properties.zona == +idparts[3] &&
                  f.properties.poligono == +idparts[4] &&
                  f.properties.parcela == +idparts[5];
                });
                  console.log("Encontrada...")
                  console.log(matchFeature);
                  resolver(matchFeature);




              });






        });



    });
  }

}
