import { GeoTileSystemParams } from './geotilesystemparams';
import { GeoTileSystemTileIndex } from './geotilesystemtileindex';
export class GeoTileSystem {pars: any;epsilon: number;nx0: number;ny0: number;srid: any;
  constructor(a:any){
    this.srid = a;
            this.ny0 = this.nx0 = 1;
            this.epsilon = 1E-10;
            this.pars = this.GetSridParams(a);
            if (null == this.pars)
                throw Error('GeoTileSystem con SRID = ' + a + ' no soportado');
            this.LoadPixelsPerVectorUnit(a)
  }
  
  private GetSridParams(a:any) {
            return new GeoTileSystemParams(2.00375083427892E7,-2.00375083427892E7,2.00375083427892E7,-2.00375083427892E7)
        }
  
private LoadPixelsPerVectorUnit(b:any) {
           
        }
  
public XYToTileIJ(a:any, b:any, c:any) {
            var e = Math.pow(2, c);
            a = parseInt(((a - this.pars.XMin) / ((this.pars.XMax - this.pars.XMin) / (e * this.nx0))).toString());
            b = parseInt(((b - this.pars.YMin) / ((this.pars.YMax - this.pars.YMin) / (e * this.ny0))).toString());
            return new GeoTileSystemTileIndex(c,a,b)
        }
}